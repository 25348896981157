
import ContractsSubscriptionsService from "@/modules/contracts-subscriptions/contracts-subscriptions-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            ContractsSubscriptionsService.create(payload).then(response => {
                resolve({
                    message: "ContractsSubscriptions criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar ContractsSubscriptions",
                    success: false,
                    error
                });
            })
        })
    },
    update(id,payload) {
        return new Promise((resolve, reject) => {
            ContractsSubscriptionsService.update(id,payload).then(response => {
                resolve({
                    message: "ContractsSubscriptions atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar ContractsSubscriptions",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            ContractsSubscriptionsService.remove(id).then(response => {
                resolve({
                    message: "ContractsSubscriptions removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover ContractsSubscriptions",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            ContractsSubscriptionsService.delete(id).then(response => {
                resolve({
                    message: "ContractsSubscriptions deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar ContractsSubscriptions",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            ContractsSubscriptionsService.get(id).then(response => {
                resolve({
                    message: "ContractsSubscriptions encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar ContractsSubscriptions",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            ContractsSubscriptionsService.paginate(query).then(response => {
                resolve({
                    message: "ContractsSubscriptions encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar ContractsSubscriptions",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            ContractsSubscriptionsService.all().then(response => {
                resolve({
                    message: "ContractsSubscriptions encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar ContractsSubscriptions",
                    success: false,
                    error
                });
            })
        })
    }
}
